// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-narrators-jsx": () => import("./../../../src/pages/narrators.jsx" /* webpackChunkName: "component---src-pages-narrators-jsx" */),
  "component---src-pages-opt-out-jsx": () => import("./../../../src/pages/opt-out.jsx" /* webpackChunkName: "component---src-pages-opt-out-jsx" */),
  "component---src-pages-player-widget-jsx": () => import("./../../../src/pages/player-widget.jsx" /* webpackChunkName: "component---src-pages-player-widget-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-publishers-jsx": () => import("./../../../src/pages/publishers.jsx" /* webpackChunkName: "component---src-pages-publishers-jsx" */),
  "component---src-pages-publishers-yaml-slug-js": () => import("./../../../src/pages/{PublishersYaml.slug}.js" /* webpackChunkName: "component---src-pages-publishers-yaml-slug-js" */)
}

